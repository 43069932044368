<template>
  <v-card class="my-5" flat>
    <div class="d-flex align-center">
      <span class="d-inline-block font-weight-bold title ml-5">
        {{ $t("permission.permission_list") }}
      </span>
      <v-spacer />
      <v-btn
        class="gradient-primary ma-2"
        dark
        depressed
        @click="openDialog = true"
      >
        {{ $t("permission.added_my_permission") }}
        <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
      </v-btn>
    </div>
    <Table :table="table" />
    <Pagination
      :length="table.dataTable.last_page"
      :total="table.dataTable.total"
      :current-page="table.page"
      :limit="table.limit"
      :handler="paginateChange"
      class="ma-3"
    />
    <v-dialog
      v-model="openDialog"
      transition="scale-transition"
      persistent
      max-width="750px"
    >
      <v-card flat>
        <v-card-title>
          {{ $t("permission.added_my_permission") }}
          <div style="position: absolute; right: 25px;">
            <v-icon :disabled="loadingSave" @click="closeDialog"
              >mdi-close</v-icon
            >
          </div>
        </v-card-title>
        <v-card-text>
          <FormPermission
            :bodyRequest="bodyRequest"
            :listPermission="listMaster"
            :loading="loadingMaster"
            :checkValidate="validateForm"
            :reset="resetForm"
          />
        </v-card-text>
        <v-divider class="my-3" />
        <v-card-actions>
          <v-row no-gutters justify="end">
            <div style="float: right">
              <v-btn
                :disabled="loadingSave"
                :dark="!loadingSave"
                outlined
                color="primary"
                class="mr-2"
                @click="closeDialog"
                >{{ $t("app.cancel") }}</v-btn
              >
              <v-btn
                :loading="loadingSave"
                color="gradient-primary"
                depressed
                dark
                @click="save()"
              >
                {{ $t("app.save") }}
              </v-btn>
            </div>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  getListPermission,
  addPermission,
  getListMaster
} from "@/api/admin/absent/permission";
import { uploadFile } from "@/api/admin/admin";

export default {
  components: {
    Table: () => import("../components/Table"),
    Pagination: () => import("@/components/Pagination"),
    FormPermission: () => import("../components/FormPermission")
  },
  created() {
    this.getPermission();
  },
  watch: {
    openDialog(value) {
      if (value) {
        this.resetForm = false;
        this.masterPermission();
      }
    }
  },
  data() {
    return {
      listMaster: [],
      loadingMaster: false,
      loadingSave: false,
      resetForm: false,
      validateForm: false,
      openDialog: false,
      bodyRequest: {
        leave: 0,
        type: "student",
        date_in: "",
        date_out: "",
        file: "",
        temp_file: null,
        desc: ""
      },
      table: {
        dataTable: { data: [] },
        loading: false,
        page: 1,
        limit: 10
      }
    };
  },
  methods: {
    async masterPermission() {
      this.loadingMaster = true;
      try {
        const response = await getListMaster({ prefix: "student" });
        if (response.data.code) {
          this.listMaster = response.data.data;
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        this.snackBar(true, error);
      }
      this.loadingMaster = false;
    },
    async uploadImage() {
      let result = "";
      let bodyImage = new FormData();
      bodyImage.set("path", "permission/teacher");
      bodyImage.set("module", "PERMISSION");
      bodyImage.append("file", this.bodyRequest.temp_file);
      if (this.bodyRequest.temp_file) {
        try {
          const response = await uploadFile(bodyImage);
          if (response.data.code) {
            result = response.data.data.path;
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          this.snackBar(false, error);
        }
      }
      return result;
    },
    async save() {
      this.validateForm = false;
      if (
        this.bodyRequest.leave &&
        this.bodyRequest.date_in &&
        this.bodyRequest.date_out
      ) {
        this.loadingSave = true;
        try {
          this.bodyRequest.file = await this.uploadImage();
          const response = await addPermission(this.bodyRequest);
          if (response.data.code) {
            this.snackBar(false, response.data.message);
            if (response.data.data && response.data.data.length > 0) {
              response.data.data.map(r => {
                this.$socket.emit("serverNotif", {
                  recipient: r.recipient,
                  data: r
                });
              });
            }
            this.closeDialog();
          } else {
            this.snackBar(true, response.data.message);
          }
        } catch (error) {
          this.snackBar(true, error);
        }
        this.loadingSave = false;
      } else {
        this.validateForm = true;
      }
    },
    reset() {
      this.dataEmployee = [];
      this.bodyRequest = {
        leave: 0,
        type: "student",
        date_in: "",
        date_out: "",
        file: "",
        temp_file: null,
        desc: ""
      };
      this.resetForm = true;
    },
    closeDialog() {
      this.openDialog = false;
      this.reset();
      this.getPermission();
    },
    async getPermission() {
      this.table.loading = true;
      try {
        const response = await getListPermission({
          type: "student",
          page: this.table.page,
          limit: this.table.limit
        });
        if (response.data.code) {
          this.table.dataTable = response.data.data;
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        this.snackBar(true, error);
      }
      this.table.loading = false;
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
    },
    paginateChange(page, limit) {
      this.table.page = page;
      this.table.limit = limit;
      this.getPermission();
    }
  }
};
</script>
